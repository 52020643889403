import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Global } from '../global';
import { ServerService } from '../server.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.page.html',
  styleUrls: ['./notification-settings.page.scss'],
})
export class NotificationSettingsPage{
  @Input() notificationOn:any;
  @Input() notificationSettings:any;
  @Input() vehicalId: any;
  @Input() temperatureProbes: any;
  @Input() temperatureMax: any;
  @Input() temperatureMin: any;
  constructor(public modalCtrl:ModalController,
    public global: Global,
    public server: ServerService,) {
      console.log("settings: ", this.temperatureProbes, this.temperatureMax, this.temperatureMin);
    }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  updateSettings(){
    this.global.presentToast('Please wait...', 500);
    let response = this.server.update_notification_settings(this.vehicalId,JSON.stringify(this.notificationSettings),this.notificationOn, this.temperatureProbes, this.temperatureMax, this.temperatureMin);
    response.subscribe(data => {
      this.global.presentToast(data.data.message, 1000);
      if (data.code == 200 && data.data.success) {
        this.dismiss();
      } 
    }, error => {
      // loading.dismiss();
      console.log(error);
      this.global.presentToast("Something went wrong check your internet connection.");
    });
  }

}
