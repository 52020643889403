import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'location-req',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'list',
    loadChildren: './list/list.module#ListPageModule'
  },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  { path: 'update-eta', loadChildren: './update-eta/update-eta.module#UpdateEtaPageModule' },
  { path: 'shipment-detail', loadChildren: './shipment-detail/shipment-detail.module#ShipmentDetailPageModule' },
  { path: 'location-req', loadChildren: './location-req/location-req.module#LocationReqPageModule' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
  { path: 'vehicle-details/:id', loadChildren: './vehicle-details/vehicle-details.module#VehicleDetailsPageModule' },
  { path: 'list-location', loadChildren: './list-location/list-location.module#ListLocationPageModule' },
  { path: 'add-location', loadChildren: './add-location/add-location.module#AddLocationPageModule' },
  { path: 'update-location', loadChildren: './update-location/update-location.module#UpdateLocationPageModule' },
  { path: 'notification-settings', loadChildren: './notification-settings/notification-settings.module#NotificationSettingsPageModule' },
  { path: 'vehicles', loadChildren: './vehicles/vehicles.module#VehiclesPageModule' },
  { path: 'select-vehicle-icon', loadChildren: './select-vehicle-icon/select-vehicle-icon.module#SelectVehicleIconPageModule' },
  { path: 'fuel-report/:id', loadChildren: './fuel-report/fuel-report.module#FuelReportPageModule' },
  { path: 'vehicle-list', loadChildren: './vehicle-list/vehicle-list.module#VehicleListPageModule' },
  { path: 'stream/:deviceId', loadChildren: './pages/stream/stream.module#StreamPageModule' },
  { path: 'clips-list/:id', loadChildren: './pages/clips-list/clips-list.module#ClipsListPageModule' },
  { path: 'request-clip', loadChildren: './pages/request-clip/request-clip.module#RequestClipPageModule' },
  { path: 'trips/:id', loadChildren: './trips/trips.module#TripsPageModule' },
  { path: 'play-list', loadChildren: './play-list/play-list.module#PlayListPageModule' },
  { path: 'stream-sw/:deviceId', loadChildren: './stream-sw/stream-sw.module#StreamSwPageModule' },
  // { path: 'vehicle-list', redirectTo: 'vehicle-list', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
