import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, LoadingController, ToastController } from '@ionic/angular';
import { Global } from '../global';
import { ServerService } from '../server.service';

declare var google;

@Component({
  selector: 'app-update-location',
  templateUrl: './update-location.page.html',
  styleUrls: ['./update-location.page.scss'],
})
export class UpdateLocationPage implements OnInit {

  map;
  longitude : any;
  latitude : any;
  address: any;
  name : any;
  location_id : any;
  radius: any;
  data : any;

  constructor(
    public navCtrl: NavController,
    public global:Global,
    public server: ServerService,
    public loadingCtrl: LoadingController,
    public toastController: ToastController,
    public modalCtrl : ModalController,
  ) { }

  ngOnInit() {
    console.log(this.global.location_list);
    this.data = this.global.location_list
    console.log("location data",this.data)
    this.location_id = this.data.locationId;
    console.log("id",this.location_id)
    this.name = this.data.name;
    console.log("name",this.name)
    this.longitude = this.data.longitude;
    console.log("long",this.longitude)
    this.latitude = this.data.latitude;
    console.log("lati",this.latitude)
    this.address = this.data.address;
    console.log("address",this.address)
    this.radius = this.data.radius;
    console.log("radius",this.radius)
    
    this.loadMap();
  }
  modelclose() {
    this.modalCtrl.dismiss();
  }


  async Updatelocation(){
       
        let response = this.server.update_location(this.location_id,this.name,this.address,this.latitude,this.longitude,this.radius, this.global.location_list.monitoringStatus);
        const loading = await this.loadingCtrl.create({
          message: 'Please wait',
        });
          await loading.present();
    
        response.subscribe(res =>{
          console.log("response",res);
           console.log("data",res.data);
           loading.dismiss();
  
           if (res.data.success === true) {
            
           this.global.presentToast(res.data.message);
           this.modalCtrl.dismiss();
        }
           else {
            console.log('success false');
            this.global.presentAlert('Error!', res.data.message, 'OK');
           }
  
          },error =>{
            console.log("Error occured", error);
             loading.dismiss();
             this.global.alertMsg("Failure", "Something went wrong. Please check your connection");
           });
      }



      loadMap() {
          var map = new google.maps.Map(document.getElementById('map'), {
          zoom: 10,
          center: new google.maps.LatLng(this.latitude,this.longitude),
        });

              var marker = new google.maps.Marker({
          position:  map.getCenter(),
          map: map,
          title: '', 
          draggable:true,
          animation: google.maps.Animation.DROP,

        }); 
              let content = "";         
              this.addInfoWindow(marker, content);
         }
  

         addInfoWindow(marker, content)
         {
           var that = this;
             let infoWindow = new google.maps.InfoWindow({
               content: content,
             });
             google.maps.event.addListener(marker, 'click', () => 
             {
               infoWindow.open(this.map, marker);
             });
              google.maps.event.addListener(marker, 'dragend', function()
                    {
                        this.markerlatlong = marker.getPosition();
      
                        this.longitude = marker.getPosition().lng();
                        this.latitude = marker.getPosition().lat();
                        that.latitude = this.latitude;
                        that.longitude = this.longitude;
    
                        console.log("longitude" , this.longitude)
                        console.log("latitude",this.latitude)
                     
                        var geocoder = new google.maps.Geocoder;
                        let latlng = {lat: this.latitude, lng: this.longitude};
                        
                        geocoder.geocode({'location': latlng}, (results, status) => {
                           console.log("result_address",results); 
                           console.log(status);
                           this.address = results[0].formatted_address;
                           console.log("address",this.address)
                           that.address = this.address;
                           console.log("global address",that.address)
                         });
                    });       
          }

}
