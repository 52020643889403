import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Global } from '../global';
import { ServerService } from '../server.service';

@Component({
  selector: 'app-select-vehicle-icon',
  templateUrl: './select-vehicle-icon.page.html',
  styleUrls: ['./select-vehicle-icon.page.scss'],
})
export class SelectVehicleIconPage implements OnInit {
  @Input() vehicalId: any;

  constructor(public modalCtrl:ModalController,
    public global: Global,
    public server: ServerService) { }

  ngOnInit() {
    console.log("icons list", this.global.vehicleIcons);
  }
  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  selectIcon(icon){
    this.global.presentToast('Please wait...', 500);
    let response = this.server.update_vehicle_icon(this.vehicalId,icon.image);
    response.subscribe(data => {
      this.global.presentToast(data.data.message, 1000);
      if (data.code == 200 && data.data.success) {
        this.dismiss();
      } 
    }, error => {
      // loading.dismiss();
      console.log(error);
      this.global.presentToast("Something went wrong check your internet connection.");
    });
  }




}
